import { startLoader, stopLoader } from './commonActions';
import { getAxiosWithToken } from '../utils/webApi';
import { LOAD_TRACKING_RECORDS, CLEAR_TRACKING_RECORDS } from './actionTypes';

export const addTrackingRecord =
  (subTypeId, date, quantity, additionalInfo) => async (dispatch) => {
    dispatch(startLoader());
    await getAxiosWithToken().post('trackers/records', {
      subTypeId,
      date,
      quantity,
      additionalInfo,
    });

    dispatch(getAllTrackingRecords());
  };

export const updateTrackingRecord = (id, date, quantity, additionalInfo, notes) => async (dispatch) => {
  dispatch(startLoader());

  try {
    const response = await getAxiosWithToken().put(`trackers/records/${id}`, {
      id,
      date,
      quantity,
      additionalInfo,
      notes,
    });

    dispatch(stopLoader());

    if (response.data.success) {
      const updatedRecord = response.data.data;
      dispatch({
        type: 'UPDATE_TRACKING_RECORD',
        payload: updatedRecord,
      });
    }

  } catch (error) {
    console.error('Error updating tracking record:', error);
    dispatch(stopLoader());
  }
};

export const deleteTrackingRecord = (id) => async (dispatch) => {
  dispatch(startLoader());

  try {
    const response = await getAxiosWithToken().delete(`trackers/records/${id}`);

    dispatch(stopLoader());

    if (response.data.success) {
      dispatch({
        type: CLEAR_TRACKING_RECORDS,
        payload: id,
      });
    }

  } catch (error) {
    console.error('Error deleting tracking record:', error);
    dispatch(stopLoader());
  }
};

export const getAllTrackingRecords = () => async (dispatch) => {
  dispatch(startLoader());

  let offset = 0;
  const batchSize = 200;
  let hasMoreRecords = true;

  while (hasMoreRecords) {
    try {
      const response = await getAxiosWithToken().get(`trackers/records?offset=${offset}&batchSize=${batchSize}`);
      dispatch(stopLoader());

      const returnedData = response.data;

      if (returnedData.success) {
        if (returnedData.data.length > 0) {
          dispatch({ type: LOAD_TRACKING_RECORDS, payload: returnedData.data });
        }

        if (returnedData.data.length < batchSize) {
          hasMoreRecords = false;
        } else {
          offset += batchSize;
        }
      } else {
        console.error('Error fetching records:', returnedData);
        hasMoreRecords = false;
      }
    } catch (error) {
      console.error('Error fetching records:', error);
      hasMoreRecords = false;
    }
  }
};

export const getTrackingRecordsByType = (typeId) => async (dispatch) => {
  dispatch(startLoader());

  let offset = 0;
  const batchSize = 200;
  let hasMoreRecords = true;

  while (hasMoreRecords) {
    try {
      const response = await getAxiosWithToken().get(
        `trackers/records/type?typeId=${typeId}&offset=${offset}&batchSize=${batchSize}`
      );

      const returnedData = response.data;
      dispatch(stopLoader());

      if (returnedData.success) {
        if (returnedData.data.length > 0) {
          dispatch({ type: LOAD_TRACKING_RECORDS, payload: returnedData.data });
        }

        if (returnedData.data.length < batchSize) {
          hasMoreRecords = false;
        } else {
          offset += batchSize;
        }
      } else {
        console.error('Error fetching records by type:', returnedData);
        hasMoreRecords = false;
      }
    } catch (error) {
      console.error('Error fetching records by type:', error);
      hasMoreRecords = false;
      dispatch(stopLoader());
    }
  }
};
