export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;
export const SYSTEM_IMAGES_RELATIVE_URL =
  process.env.REACT_APP_SYSTEM_IMAGES_RELATIVE_URL;
export const USER_IMAGES_RELATIVE_URL =
  process.env.REACT_APP_USER_IMAGES_RELATIVE_URL;
export const STICKER_FILE_SIZE_LIMIT_IN_MB =
  process.env.REACT_APP_STICKER_FILE_SIZE_LIMIT_IN_MB;
export const GOOGLE_CAPTCHA_SITE_KEY =
  process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;
export const USER_REGISTRATION_DISABLED = process.env
  .REACT_APP_USER_REGISTRATION_DISABLED
  ? JSON.parse(process.env.REACT_APP_USER_REGISTRATION_DISABLED.toLowerCase())
  : false;

export const TOKEN_IN_LOCAL_STORAGE = 'USER_TOKEN';
export const USER_DATA_IN_LOCAL_STORAGE = 'USER_DATA';
export const SELECTED_GOAL_IN_LOCAL_STORAGE = 'SELECTED_GOAL';
export const SELECTED_TAG_IN_LOCAL_STORAGE = 'SELECTED_TAG';
export const SELECTED_STICKER_IN_LOCAL_STORAGE = 'SELECTED_STICKER';
export const ADD_STICKER_IN_LOCAL_STORAGE = 'ADD_STICKER';
export const GOALS = 'GOALS';
export const SELECTED_STICKER_IMAGE_DATA = 'IMAGE_DATA';
export const PAID_FUNCTIONALITY_CREDENTIALS_IN_LOCAL_STORAGE =
  'PAID_FUNCTIONALITY_CREDENTIALS_IN_LOCAL_STORAGE';

export const DEFAULT_LOCALE_CODE = 'bg';
export const ENGLISH_LOCALE_CODE = 'en';

export const DEFAULT_BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD.MM.YYYY';

export const EMPTY_STRING = '';
export const FOUR_WEEKS_BEFORE_DUE_DATE = 30;

export const AFTER_ONE_YEAR_MONTH_NUMBER = 13;

export const PWA_VERSION = 11;

export const REQUEST_RESPONSE_CODE = {
  NOT_ACCEPTABLE_406: 406,
};

export const NUMBER_OF_BYTES_IN_MB = 1048576;
export const IMAGE_MAX_SIZE_IN_BYTES =
  process.env.REACT_APP_STICKER_FILE_SIZE_LIMIT_IN_MB * NUMBER_OF_BYTES_IN_MB;
export const SUPPORTED_IMAGE_FORMATS = ['JPEG', 'JPEG', 'PNG'];

export const MIN_TEXT_FIELD_LENGTH = {
  PASSWORD: 6,
  USERNAME: 6,
};

export const MAX_TEXT_FIELD_LENGTH = {
  SHORT: 20,
  NORMAL: 100,
  LONG: 250,
  VERY_LONG: 1000,
};

export const ALLOWED_SPECIALISTS_PER_USER = 20;

export const USEFUL_INFO_MAX_MONTH = 12;

export const FORMS = {
  REGISTER_FORM: 'registerForm',
  PROFILE_FORM: 'profileForm',
  TAG_FORM: 'tagForm',
  STICKER_FORM: 'stickerForm',
  SPECIALIST_FORM: 'specialistForm',
  CONTACT_FORM: 'contactForm',
  ADMIN_FORM: 'adminForm',
};

export const RELATIVE_PAGE_PATHS = {
  HOME: '/',
  REGISTER: '/register',
  SELECTED_TAG: '/tag/',
  ACTIVATE_PROFILE: '/activate-profile',
  DELETE_PROFILE: '/delete-profile',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
  REQUEST_PASSWORD_RESET: '/request-password-reset',
  RESET_PASSWORD: '/reset-password',
  LOGIN: '/login',
  NOT_FOUND: '/404',
  INTERNAL_SERVER_ERROR: '/500',
  UNHANDLED_ERROR: '/unhandled-error',
  JOURNAL: '/journal',
  ANALYTICS: '/analytics',
  REQUEST_JOURNAL_DOWNLOAD: '/request-journal-download',
  JOURNAL_READ_ONLY: '/read-only-journal',
  EXPIRED_STICKERS: '/expired-stickers',
  ADD_STICKER: '/add-sticker-to-journal/',
  EDIT_STICKER: '/sticker/',
  STICKER_READ_ONLY: '/read-only-sticker/',
  SPECIALISTS: '/consult/',
  ADD_SPECIALISTS: '/add-consult/',
  CONTACTS: '/contact/',
  ADD_CONTACT: '/add-contact/',
  ADMIN_ACTIONS: '/admin-actions',
  USEFUL_INFO: '/useful-info',
  PHOTO_PREVIEW: '/photo/',
  BREAST_FEEDING: '/tracking/breast-feeding',
  TRACKING: '/tracking',
  TRACK: '/track',
  SLEEP: '/tracking/sleep',
  CRY: '/tracking/cry',
  ADDHOMESCREEN: '/addHomeScreen',
  WEIGHT_TRACKER: '/tracking/8',
  HEIGHT_TRACKER: '/tracking/21',
  HEAD_CIRCUMFERENCE_TRACKER: '/tracking/22',
  PAYMENTS: '/payments',
  SUBSCRIPTION_PLANS: '/payments/subscription-plans',
  CHECKOUT: '/payments/checkout',
  RETURN: '/payments/return',
  TERMS_AND_CONDITIONS: '/policies/terms-and-conditions',
  GDPR: '/policies/gdpr',
  CONTACTS_POLICIES: '/policies/contacts',
};

export const EXTERNAL_URLS = {
  TERMS_AND_CONDITIONS: 'https://www.namama.bg/page/obshti-usloviya',
  GDPR: 'https://www.namama.bg/GDPR/',
  POLICY: 'https://www.namama.bg/',
  NEWSLETTER: 'https://www.namama.bg/newsletter/',
};

export const COLOR_SCHEME = {
  DARK: '#4b4b4b',
  LIGHTER_DARK: '#9f9f9f',
  GREY: '#848484',
  DARK_GREY: '#adadad',
  LIGHT_BLUE: '#f1f9ff',
  WHITE: '#ffffff',
  SHADOW_COLOR: '#F7F7F7',
  LIGHT: '#f5f5f5',
  LIGHT_GRAY: '#cacaca',
  GRAY: '#c9c9c9',
  LIGHTER_GREEN: '#f1ffd5',
  GREEN: '#87ba3b',
  LIGHTER_YELLOW: '#fffded',
  YELLOW: '#ffd400',
  ORANGE: '#ff8901',
  RED: '#fe3f43',
};

export const DMT_WEBSITE = 'https://namamaitatko.bg/';
export const BIRTH_STICKER_NAME = 'festiveMoments.birth.name';

export const DOWNLOADED_JOURNAL_FILE_NAME = 'Дневник.zip';

export const TRACKERS_TYPE = {
  TRACKING: 'tracking',
  FEEDING: 'feeding',
  MEASUREMENT: 'measurement',
  CHANGE_DIAPERS: 'change-diapers',
  MULTI_VALUE_MEASUREMENT: 'multi-value-measurement',
  TIME_VALUE: 'timeValue',
};

export const TRACKERS_SUB_TYPE = {
  BREAST_FEEDING: 'breast-feeding',
  CRY: 'cry',
  SLEEP: 'sleep',
};

export const MULTI_VALUE_MEASUREMENT_SUBTYPES = {
  FEEDING: 'feeding',
  MEDICINE: 'medicine',
};

export const QUANTITY_DEFAULT_VALUES = {
  breathing: 50,
  temperature: 37,
  tummyTime: 30,
  massageAndGymnastics: 30,
  bathing: 30,
  outdoor: 30,
  reading: 30,
  play: 30,
  potty: 30,
  height: 50,
  headCircumference: 32,
};

export const FOOD_FORM_WORDS = {
  PUREE: 'puree',
  PORRIDGE: 'porridge',
  CEREALMILKPORRIDGE: 'cerealMilkPorridge',
  MASHED: 'mashed',
  GRATED: 'grated',
  SLICED: 'sliced',
  MINCED: 'minced',
  FINGERFOOD: 'fingerFood',
  BROTH: 'broth',
  SOUP: 'soup',
  BREAD: 'bread',
  CROUTON: 'crouton',
  BISCUIT: 'biscuit',
  PASTA: 'pasta',
  MUSH: 'mush',
  BANITSA: 'banitsa',
  CAKE: 'cake',
  SALAD: 'salad',
};

export const foodWordsWithComma = [
  FOOD_FORM_WORDS.SLICED,
  FOOD_FORM_WORDS.MASHED,
  FOOD_FORM_WORDS.GRATED,
  FOOD_FORM_WORDS.FINGERFOOD,
  FOOD_FORM_WORDS.MINCED,
];

export const MULTIVALUE_COLOR_TEMPLATES = {
  GREEN: {
    background: '#f1ffd5',
    color: '#87ba3b',
    justifyContent: 'center',
    opacity: 1,
  },
  PINK: {
    background: '#FFE1EB',
    color: '#CC8EAC',
    justifyContent: 'center',
    opacity: 1,
  },
  ORANGE: {
    background: '#fff4d7',
    color: '#fd9112',
    justifyContent: 'center',
    opacity: 1,
  },
  DARK_ORANGE: {
    background: '#fedabe',
    color: '#ffa261',
    justifyContent: 'center',
    opacity: 1,
  },
  BROWN: {
    background: '#efd7c7',
    color: '#b28b66',
    justifyContent: 'center',
    opacity: 1,
  },
  LIGHT_BLUE: {
    background: '#daf1f7',
    color: '#6cc7e6',
    justifyContent: 'center',
    opacity: 1,
  },
  PURPLE: {
    background: '#ead3e5',
    color: '#ad92bd',
    justifyContent: 'center',
    opacity: 1,
  },
  MINT: {
    background: '#d1ede8',
    color: '#50c3ba',
    justifyContent: 'center',
    opacity: 1,
  },
  LIGHT_ORANGE: {
    background: '#fff8d6',
    color: '#ffc82b',
    justifyContent: 'center',
    opacity: 1,
  },
  DARK_GREEN: {
    background: '#daeac7',
    color: '#7ba968',
    justifyContent: 'center',
    opacity: 1,
  },
};

export const TRACKER_SUBTYPES = {
  BOTTLE_FORMULA: 'bottle-formula',
  BOTTLE_BREAST_MILK: 'bottle-breast-milk',
  BREAST_FEEDING: 'breast-feeding',
  BOTTLE_WATER: 'bottle-water',
  WET_DIAPER: 'wet-diaper',
  POO_DIAPER: 'poo-diaper',
  WEIGHT: 'weight',
  HEIGHT: 'height',
  HEAD_CIRCUMFERENCE: 'headCircumference',
  BREAST_PUMP: 'breast-pump',
  MEDICINE: 'medicine',
  SLEEP: 'sleep',
  CRY: 'cry',
  BREATHING: 'breathing',
  TEMPERATURE: 'temperature',
  TUMMY_TIME: 'tummyTime',
  MESSAGE_AND_GYMNASTICS: 'massageAndGymnastics',
  BATHING: 'bathing',
  OUTDOOR: 'outdoor',
  READING: 'reading',
  PLAY: 'play',
  POTTY: 'potty',
  FEEDING: 'feeding',
};

export const CHART_TYPE = {
  WEIGHT: 'weight',
  HEIGHT: 'height',
  HEAD_CIRCUMFERENCE: 'headCircumference',
  WEIGHT_FOR_LENGTH: 'weightForLength',
};

export const TWO_YEARS = 730;
export const DAYS_AFTER_LAST_RECORD = 30;
export const DAYS_PER_MONTH_WHO = 30.45;

export const APPROXIMATE_DAYS_PER_MONTH_WHO = {
  30: 1,
  61: 2,
  91: 3,
  122: 4,
  152: 5,
  183: 6,
  213: 7,
  244: 8,
  274: 9,
  304: 10,
  335: 11,
  365: 12,
  396: 13,
  426: 14,
  457: 15,
  487: 16,
  517: 17,
  548: 18,
  578: 19,
  609: 20,
  639: 21,
  670: 22,
  700: 23,
  731: 24,
};
// WHO data records for weight/height

export const WEIGHT_FOR_LENGTH_RECORDS = [
  45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
  83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
  102, 103, 104, 105, 106, 107, 108, 109, 110,
];
export const WEIGHT_FOR_LENGTH_RECORD_NUMBER = WEIGHT_FOR_LENGTH_RECORDS.length;

export const DAYS_SIX_MONTHS = 183;
export const DAYS_ONE_YEAR = 365;

export const FIRST = 'FIRST';
export const LAST = 'LAST';

export const PERCENTILE_COLORS = {
  GREEN: '#87BA3B',
  ORANGE: '#F2A75C',
  RED: '#EF5D6B',
};

export const CHART_TYPE_OPTIONS = {
  weight: {
    interval: 1,
    min: 2,
  },
  height: {
    interval: 5,
    min: 45,
  },
  headCircumference: {
    interval: 1,
    min: 32,
  },
  weightForLength: {
    interval: 2,
    min: 2,
  },
};

export const CHART_OPTIONS = {
  HEIGHT: {
    height: 'auto',
  },
  GRID: {
    top: '0.1px%',
    containLabel: true,
    show: true,
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#000',
  },
  Y_AXIS: {
    type: 'value',
    nameLocation: 'center',
    axisLabel: {
      showMaxLabel: false,
    },
    nameTextStyle: {
      fontSize: 15,
      padding: [0, 0, 25, 0],
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#eee',
        width: 1,
      },
    },
  },
  X_AXIS: {
    type: 'category',
    nameLocation: 'center',
    nameTextStyle: {
      fontSize: 15,
      padding: [15, 0, 0, 0],
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#eee',
        width: 1,
      },
    },
  },
  TOOLTIP: {
    show: true,
    trigger: 'item',
    borderWidth: 0,
  },
  DATAZOOM: [
    {
      type: 'inside',
      xAxisIndex: 0,
    },
    {
      type: 'inside',
      yAxisIndex: 0,
    },
  ],
  SERIES: {
    CHILD: {
      type: 'line',
      smooth: true,
      legendHoverLink: true,
      symbol: 'circle',
      symbolSize: 11,
      z: 20,
      color: '#717171',
      connectNulls: true,
      showAllSymbol: true,
      lineStyle: {
        width: 1.5,
      },
    },
    P3: {
      name: 'P3',
      type: 'line',
      smooth: true,
      legendHoverLink: true,
      symbol: 'circle',
      symbolSize: 0,
      cursor: 'none',
      color: '#CAD0DA',
      lineStyle: {
        width: 0.6,
      },
      endLabel: {
        show: true,
        formatter: (params) => {
          return params.seriesName;
        },
        color: PERCENTILE_COLORS.RED,
      },
    },
    P15: {
      name: 'P15',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 0,
      color: '#CAD0DA',
      lineStyle: {
        width: 0.6,
      },
      cursor: 'none',
      endLabel: {
        show: true,
        formatter: (params) => {
          return params.seriesName;
        },
        color: PERCENTILE_COLORS.ORANGE,
      },
    },
    P50: {
      name: 'P50',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 0,
      color: '#CAD0DA',
      lineStyle: {
        width: 0.6,
      },
      cursor: 'none',
      endLabel: {
        show: true,
        formatter: (params) => {
          return params.seriesName;
        },
        color: PERCENTILE_COLORS.GREEN,
      },
    },
    P85: {
      name: 'P85',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 0,
      color: '#CAD0DA',
      lineStyle: {
        width: 0.6,
      },
      cursor: 'none',
      endLabel: {
        show: true,
        formatter: (params) => {
          return params.seriesName;
        },
        color: PERCENTILE_COLORS.ORANGE,
      },
    },
    P97: {
      name: 'P97',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 0,
      color: '#CAD0DA',
      lineStyle: {
        width: 0.6,
      },
      cursor: 'none',
      endLabel: {
        show: true,
        formatter: (params) => {
          return params.seriesName;
        },
        color: PERCENTILE_COLORS.RED,
      },
    },
  },
};
//stripe constants
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const STARTING_POINT_URL = 'startingPointUrl';
export const SELECTED_PLAN_ID = 'selectedPlanId';
export const OPEN = 'open';
export const COMPLETE = 'complete';
export const CANCELED = 'canceled';
//stripe error codes
export const CARD_DECLINED = 'card_declined';
export const CARD_EXPIRED = 'expired_card';
export const PROCESSING = 'processing_error';
export const INCORED_CVC = 'incorrect_cvc';
export const INCOMPLETE_CVC = 'incomplete_cvc';
export const INCORRECT_NUMBER = 'incorrect_number';
export const AUTH_FAILURE = 'payment_intent_authentication_failure';

//github constants
export const REPO_OWNER = 'REMEMBRAND';
export const REPO_NAME = 'dmt-web';
export const BRANCH = 'main';
export const WORKFLOW_ID = 'dev.yml';
export const INSTRUCTIONS = 'Инструкции';

export const JOURNAL_EMAIL = 'dnevnikat@namamaitatko.bg';
export const RELATIVE_LINKS_PROD = {
  BREAST_FEEDING: 'breast-feeding',
  SLEEP: 'sleep',
  CRY: 'cry',
  BOTTLE_FORMULA: 1,
  BOTTLE_BREAST_MILK: 2,
  WET_DIAPER: 5,
  POO_DIAPER: 7,
  WEIGHT_TRACKER: 8,
  HEIGHT_TRACKER: 21,
  HEAD_CIRCUMFERENCE_TRACKER: 22,
  BATHING: 16,
  TEMPERATURE: 12,
  FEEDING: 23,
  MEDICINE: 10,
};

export const LANGUAGE_CODE_KEY = 'i18nextLng';
