import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import bg from './translations/bg.json';
import en from './translations/en.json';
import { LANGUAGE_CODE_KEY } from './utils/constants';

let resourceFiles = {
  bg: bg,
  en: en,
};

const detectionOptions = {
  order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: detectionOptions,
    interpolation: { escapeValue: false },
    resources: resourceFiles,
    lng: localStorage.getItem(LANGUAGE_CODE_KEY),
    fallbackLng: 'en', //Default language
    // debug: true,
  });

export default i18next;

//Used for selector component
export const languages = [
  {
    code: 'bg',
    name: 'Български',
    country_code: 'bg',
    languageLabel: 'Език',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
    languageLabel: 'Language',
  },
  // {
  //   code: 'ro',
  //   name: 'Română',
  //   country_code: 'ro',
  //   languageLabel: 'Limbă',
  // },
  // {
  //   code: 'de',
  //   name: 'Deutsch',
  //   country_code: 'de',
  //   languageLabel: 'Sprache',
  // },
  // {
  //   code: 'ru',
  //   name: 'Русский',
  //   country_code: 'ru',
  //   languageLabel: 'Язык',
  // },
];
